import React from "react";
import { Link, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import * as Styles from "../styles/effect.module.css";

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
// import "../css/@wordpress/block-library/build-style/style.css"
// import "../css/@wordpress/block-library/build-style/theme.css"
import "../styles/blog.css";
// import Bio from "../components/bio";
import BlogLayout from "../components/bloglayout";
import Seo from "../components/seo";

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const featuredImage = {
    data: post.featuredImage?.node?.sourceUrl
      .replace("cms.katsana.com", "web-cdn.katsana.com")
      .replace(/jpg|png|jpeg/gi, "webp"),
    alt: post.featuredImage?.node?.altText || `katsana`,
    ogimage: post.featuredImage?.node?.sourceUrl
      .replace("cms.katsana.com", "web-cdn.katsana.com")
      .replace("webp", "png"),
  };

  const ogimageurl = featuredImage.ogimage;

  return (
    <BlogLayout>
      <Seo
        title={post.seo.title}
        description={post.seo.metaDesc}
        ogimage={ogimageurl}
        ogtype={post.seo.opengraphType}
        schemaMarkup={post.katsana?.schema || ``}
      />

      {/* Hero Area */}

      <div className="relative p-8 pt-12 pb-24 overflow-hidden xl:px-0 md:pt-32 md:pb-48">
        <StaticImage
          alt="katsana"
          src="../../static/images/photos/aboutus-hero.jpg"
          objectFit="cover"
          className="!absolute inset-0"
        />

        <div className="relative max-w-6xl mx-auto ">
          <p className="pt-24 text-lg md:pt-12 md:text-2xl text-bluegray">
            {post.date}
          </p>
          <h1 className="pt-2 text-3xl font-bold tracking-wide text-white md:leading-tight md:mt-0 md:text-6xl">
            {parse(post.title)}
          </h1>
          <Link
            to="/blog/"
            className="block my-6 text-lg font-medium md:text-xl text-bluegray"
          >
            ‹ Back to article list
          </Link>
        </div>

        <div className="absolute bottom-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(204,26%,93%,1)] z-10 m-[-1px] after:h-[4vh] md:after:h-[10vh]"
            }
          ></div>
        </div>
      </div>

      <article
        className="max-w-4xl p-8 xl:p-16 mx-auto bg-gradient-to-b from-[hsla(204,26%,93%,1)] to-[hsla(204,26%,99%,0)] via-white text-gray-800 relative blog-post "
        itemScope
        itemType="http://schema.org/Article"
      >
        {featuredImage?.data && (
          <img
            src={featuredImage.data}
            alt={featuredImage.alt}
            className="featured-image relative z-10 mt-[-100px] md:mt-[-180px] shadow-xl md:w-[766px] md:h-[404px] sm:w-[7698px] sm:h-[368px] w-[326px] h-[172px]"
          />
        )}

        <div className="py-8">
          {!!post.content && (
            <section
              itemProp="articleBody"
              className="prose prose-blockquote:text-primary prose-blockquote:not-italic prose-blockquote:font-semibold prose-blockquote:text-xl prose-blockquote:leading-relaxed prose-blockquote:border-gray-200 prose-blockquote:pl-5 blog-post"
            >
              {parse(post.content)}
            </section>
          )}

          <nav className="mt-16 blog-post-nav">
            <ul className="flex justify-between">
              <li className="w-1/2 pr-4">
                {previous && (
                  <Link
                    to={previous.uri}
                    rel="prev"
                    className="text-primary hover:text-primary-dark"
                  >
                    ← {parse(previous.title)}
                  </Link>
                )}
              </li>

              <li className="w-1/2 pl-4">
                {next && (
                  <Link
                    to={next.uri}
                    rel="next"
                    className="text-primary hover:text-primary-dark"
                  >
                    {parse(next.title)} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </article>
    </BlogLayout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          sourceUrl
        }
      }
      seo {
        metaDesc
        title
        opengraphType
      }
      katsana {
        schema
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`;
